import React from "react";

const CssIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.56686 28.8011L2 0H30.2149L27.6446 28.7966L16.0903 32L4.56686 28.8011Z"
      fill="#1572B6"
    />
    <path
      d="M16.1074 29.552L25.4446 26.9635L27.6411 2.35547H16.1074V29.552Z"
      fill="#33A9DC"
    />
    <path
      d="M16.1074 12.7899H20.7817L21.104 9.17271H16.1074V5.64014H24.9646L24.88 6.58757L24.0126 16.3224H16.1074V12.7899Z"
      fill="white"
    />
    <path
      d="M16.1293 21.9638L16.1133 21.9684L12.1796 20.9055L11.9281 18.0884H8.38184L8.87669 23.6347L16.1121 25.6438L16.1293 25.6392V21.9638Z"
      fill="#EBEBEB"
    />
    <path
      d="M20.4808 16.1724L20.0556 20.9027L16.1162 21.9655V25.6409L23.3574 23.6341L23.4111 23.0375L24.0248 16.1724H20.4808Z"
      fill="white"
    />
    <path
      d="M16.1197 5.64014V9.17271H7.58708L7.51622 8.37842L7.35508 6.58757L7.27051 5.64014H16.1197ZM16.1071 12.7899V16.3224H12.2225L12.1517 15.5281L11.9917 13.7373L11.9071 12.7899H16.1071Z"
      fill="#EBEBEB"
    />
  </svg>
);

export default CssIcon;
