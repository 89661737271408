import React from "react";

const TwoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.833008" y="1" width="31" height="31" rx="15.5" stroke="black" />
    <rect
      x="0.833008"
      y="1"
      width="31"
      height="31"
      rx="15.5"
      stroke="url(#paint0_linear_372_4876)"
    />
    <path
      d="M13.155 22C12.891 22 12.693 21.94 12.561 21.82C12.441 21.688 12.381 21.514 12.381 21.298C12.381 21.166 12.411 21.04 12.471 20.92C12.543 20.788 12.639 20.656 12.759 20.524L16.791 16.204C17.403 15.544 17.841 14.938 18.105 14.386C18.381 13.822 18.519 13.252 18.519 12.676C18.519 11.956 18.297 11.41 17.853 11.038C17.421 10.654 16.785 10.462 15.945 10.462C15.405 10.462 14.895 10.546 14.415 10.714C13.935 10.882 13.473 11.14 13.029 11.488C12.873 11.608 12.729 11.662 12.597 11.65C12.477 11.638 12.369 11.59 12.273 11.506C12.177 11.422 12.111 11.314 12.075 11.182C12.039 11.05 12.039 10.918 12.075 10.786C12.123 10.654 12.219 10.534 12.363 10.426C12.831 10.042 13.395 9.736 14.055 9.508C14.715 9.268 15.387 9.148 16.071 9.148C16.923 9.148 17.643 9.28 18.231 9.544C18.819 9.808 19.263 10.198 19.563 10.714C19.863 11.218 20.013 11.836 20.013 12.568C20.013 13.072 19.929 13.57 19.761 14.062C19.605 14.542 19.359 15.034 19.023 15.538C18.699 16.042 18.273 16.564 17.745 17.104L13.911 21.154V20.74H19.977C20.205 20.74 20.373 20.794 20.481 20.902C20.601 20.998 20.661 21.148 20.661 21.352C20.661 21.568 20.601 21.73 20.481 21.838C20.373 21.946 20.205 22 19.977 22H13.155Z"
      fill="url(#paint1_linear_372_4876)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_372_4876"
        x1="0.333007"
        y1="0.550857"
        x2="22.8764"
        y2="38.1153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A72B54" />
        <stop offset="1" stopColor="#C5713E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_372_4876"
        x1="10.833"
        y1="4.03973"
        x2="29.8672"
        y2="17.9952"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A72B54" />
        <stop offset="1" stopColor="#C5713E" />
      </linearGradient>
    </defs>
  </svg>
);

export default TwoIcon;
