import React from "react";

const DesignIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.9937 8.59117L17.2001 8.66197M16.6708 8.59068C16.6708 9.69525 15.7754 10.5907 14.6708 10.5907C13.5662 10.5907 12.6708 9.69525 12.6708 8.59068C12.6708 7.48611 13.5662 6.59068 14.6708 6.59068C15.7754 6.59068 16.6708 7.48611 16.6708 8.59068ZM30.6234 8.58824C30.6234 9.69281 29.728 10.5882 28.6234 10.5882C27.5188 10.5882 26.6234 9.69281 26.6234 8.58824C26.6234 7.48367 27.5188 6.58824 28.6234 6.58824C29.728 6.58824 30.6234 7.48367 30.6234 8.58824ZM10.8669 2.66211H21.5335C23.0063 2.66211 24.2002 3.85602 24.2002 5.32878V26.6621C24.2002 28.1349 23.0063 29.3288 21.5335 29.3288H10.8669C9.3941 29.3288 8.2002 28.1349 8.2002 26.6621V5.32878C8.2002 3.85602 9.3941 2.66211 10.8669 2.66211Z"
      stroke="#202023"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9937 8.59117L17.2001 8.66197M16.6708 8.59068C16.6708 9.69525 15.7754 10.5907 14.6708 10.5907C13.5662 10.5907 12.6708 9.69525 12.6708 8.59068C12.6708 7.48611 13.5662 6.59068 14.6708 6.59068C15.7754 6.59068 16.6708 7.48611 16.6708 8.59068ZM30.6234 8.58824C30.6234 9.69281 29.728 10.5882 28.6234 10.5882C27.5188 10.5882 26.6234 9.69281 26.6234 8.58824C26.6234 7.48367 27.5188 6.58824 28.6234 6.58824C29.728 6.58824 30.6234 7.48367 30.6234 8.58824ZM10.8669 2.66211H21.5335C23.0063 2.66211 24.2002 3.85602 24.2002 5.32878V26.6621C24.2002 28.1349 23.0063 29.3288 21.5335 29.3288H10.8669C9.3941 29.3288 8.2002 28.1349 8.2002 26.6621V5.32878C8.2002 3.85602 9.3941 2.66211 10.8669 2.66211Z"
      stroke="url(#paint0_linear_284_3770)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_284_3770"
        x1="8.2002"
        y1="2.70449"
        x2="28.3329"
        y2="30.9136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A72B54" />
        <stop offset="1" stopColor="#C5713E" />
      </linearGradient>
    </defs>
  </svg>
);

export default DesignIcon;
