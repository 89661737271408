import React from "react";

const UnityIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_565_15495)">
      <path
        d="M15.9973 32.0001L28.9228 24.5364L23.9686 21.6711L18.9036 24.5971C18.8604 24.6209 18.8119 24.6331 18.7627 24.6327C18.7134 24.6323 18.6651 24.6193 18.6223 24.5949C18.5797 24.5699 18.5442 24.5343 18.5194 24.4916C18.4945 24.4489 18.481 24.4005 18.4803 24.3511V17.4021C18.4803 17.1974 18.5863 17.0121 18.7636 16.9101L24.7821 13.4344C24.825 13.4099 24.8735 13.3973 24.9229 13.3977C24.9722 13.3981 25.0206 13.4115 25.0631 13.4366C25.1511 13.4854 25.2061 13.5776 25.2071 13.6781V19.5276L30.1646 22.3911V7.46387L15.9971 15.6416L15.9973 32.0001Z"
        fill="black"
      />
      <path
        d="M13.0992 24.6001L8.03171 21.6706L3.07371 24.5356L15.9977 31.9999V15.6414L1.83496 7.46338V22.3899L6.79121 19.5274V13.6784C6.79346 13.5781 6.84746 13.4859 6.93421 13.4369C6.97696 13.4117 7.02557 13.3983 7.07516 13.3979C7.12475 13.3975 7.17357 13.4102 7.21671 13.4346L13.2382 16.9099C13.3244 16.96 13.3959 17.0319 13.4456 17.1183C13.4953 17.2047 13.5214 17.3027 13.5215 17.4024V24.3524C13.5208 24.4019 13.5074 24.4504 13.4827 24.4933C13.458 24.5361 13.4227 24.572 13.3802 24.5974C13.3377 24.6224 13.2894 24.6358 13.2401 24.6363C13.1908 24.6368 13.1422 24.6244 13.0992 24.6004"
        fill="#4D4D4D"
      />
      <path
        d="M17.24 0V5.725L22.3052 8.64925C22.3922 8.7 22.444 8.79325 22.444 8.89525C22.444 8.996 22.391 9.08825 22.3052 9.139L16.2845 12.617C16.1979 12.6661 16.1002 12.6919 16.0007 12.6919C15.9012 12.6919 15.8035 12.6661 15.717 12.617L9.69796 9.139C9.65521 9.11432 9.61974 9.07877 9.59516 9.03597C9.57057 8.99316 9.55774 8.94461 9.55796 8.89525C9.5576 8.84556 9.57035 8.79664 9.59493 8.75345C9.61951 8.71026 9.65505 8.67432 9.69796 8.64925L14.7622 5.725V0L1.83496 7.46375L15.998 15.6415L30.1642 7.464L17.24 0Z"
        fill="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0_565_15495">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UnityIcon;
