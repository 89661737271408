import React from "react";

const FigmaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_580_21012)">
      <path
        d="M24.5 0H7.5C3.35786 0 0 3.35786 0 7.5V24.5C0 28.6421 3.35786 32 7.5 32H24.5C28.6421 32 32 28.6421 32 24.5V7.5C32 3.35786 28.6421 0 24.5 0Z"
        fill="#242938"
      />
      <mask
        id="mask0_580_21012"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="7"
        y="3"
        width="18"
        height="26"
      >
        <path d="M7.625 3.5H24.295V28.5H7.625V3.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_580_21012)">
        <path
          d="M11.7937 28.4999C14.0937 28.4999 15.9603 26.6333 15.9603 24.3333V20.1665H11.7937C9.4937 20.1665 7.62695 22.0333 7.62695 24.3333C7.62695 26.6333 9.4937 28.4999 11.7937 28.4999Z"
          fill="#0ACF83"
        />
        <path
          d="M7.62695 16.0001C7.62695 13.7001 9.4937 11.8335 11.7937 11.8335H15.9603V20.1667H11.7937C9.4937 20.1667 7.62695 18.3001 7.62695 16.0001Z"
          fill="#A259FF"
        />
        <path
          d="M7.62695 7.66663C7.62695 5.36663 9.4937 3.5 11.7937 3.5H15.9603V11.8334H11.7937C9.4937 11.8334 7.62695 9.96663 7.62695 7.66663Z"
          fill="#F24E1E"
        />
        <path
          d="M15.96 3.5H20.1266C22.4266 3.5 24.2933 5.36663 24.2933 7.66663C24.2933 9.96663 22.4266 11.8334 20.1266 11.8334H15.96V3.5Z"
          fill="#FF7262"
        />
        <path
          d="M24.2933 16.0001C24.2933 18.3001 22.4266 20.1667 20.1266 20.1667C17.8266 20.1667 15.96 18.3001 15.96 16.0001C15.96 13.7001 17.8266 11.8335 20.1266 11.8335C22.4266 11.8335 24.2933 13.7001 24.2933 16.0001Z"
          fill="#1ABCFE"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_580_21012">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FigmaIcon;
