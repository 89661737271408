import React from "react";

const ThreeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.16699" y="0.5" width="31" height="31" rx="15.5" stroke="black" />
    <rect
      x="1.16699"
      y="0.5"
      width="31"
      height="31"
      rx="15.5"
      stroke="url(#paint0_linear_372_4895)"
    />
    <path
      d="M16.423 21.662C15.727 21.662 15.025 21.56 14.317 21.356C13.621 21.152 13.015 20.852 12.499 20.456C12.343 20.348 12.241 20.228 12.193 20.096C12.145 19.952 12.133 19.82 12.157 19.7C12.193 19.568 12.259 19.46 12.355 19.376C12.451 19.28 12.565 19.226 12.697 19.214C12.841 19.202 12.997 19.25 13.165 19.358C13.693 19.706 14.221 19.958 14.749 20.114C15.277 20.27 15.823 20.348 16.387 20.348C16.999 20.348 17.515 20.258 17.935 20.078C18.355 19.898 18.673 19.634 18.889 19.286C19.105 18.926 19.213 18.488 19.213 17.972C19.213 17.228 18.967 16.664 18.475 16.28C17.983 15.896 17.269 15.704 16.333 15.704H15.055C14.827 15.704 14.653 15.65 14.533 15.542C14.413 15.422 14.353 15.26 14.353 15.056C14.353 14.864 14.413 14.714 14.533 14.606C14.653 14.486 14.827 14.426 15.055 14.426H16.207C16.759 14.426 17.227 14.33 17.611 14.138C18.007 13.946 18.313 13.676 18.529 13.328C18.745 12.968 18.853 12.536 18.853 12.032C18.853 11.36 18.637 10.85 18.205 10.502C17.773 10.142 17.149 9.962 16.333 9.962C15.793 9.962 15.283 10.04 14.803 10.196C14.323 10.352 13.849 10.616 13.381 10.988C13.237 11.096 13.093 11.15 12.949 11.15C12.817 11.138 12.703 11.096 12.607 11.024C12.523 10.94 12.457 10.832 12.409 10.7C12.373 10.568 12.379 10.436 12.427 10.304C12.475 10.16 12.577 10.028 12.733 9.908C13.213 9.5 13.777 9.188 14.425 8.972C15.073 8.756 15.739 8.648 16.423 8.648C17.239 8.648 17.935 8.78 18.511 9.044C19.099 9.296 19.549 9.668 19.861 10.16C20.173 10.64 20.329 11.222 20.329 11.906C20.329 12.422 20.227 12.896 20.023 13.328C19.831 13.748 19.555 14.108 19.195 14.408C18.847 14.708 18.421 14.924 17.917 15.056V14.858C18.793 15.002 19.471 15.362 19.951 15.938C20.443 16.502 20.689 17.216 20.689 18.08C20.689 18.812 20.515 19.448 20.167 19.988C19.831 20.516 19.345 20.93 18.709 21.23C18.073 21.518 17.311 21.662 16.423 21.662Z"
      fill="url(#paint1_linear_372_4895)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_372_4895"
        x1="0.666992"
        y1="0.0508574"
        x2="23.2104"
        y2="37.6153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A72B54" />
        <stop offset="1" stopColor="#C5713E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_372_4895"
        x1="11.167"
        y1="3.53973"
        x2="30.2012"
        y2="17.4952"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A72B54" />
        <stop offset="1" stopColor="#C5713E" />
      </linearGradient>
    </defs>
  </svg>
);

export default ThreeIcon;
