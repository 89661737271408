import React from "react";

const ThreejsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_565_16178)">
      <path
        d="M24.5 0H7.5C3.35786 0 0 3.35786 0 7.5V24.5C0 28.6421 3.35786 32 7.5 32H24.5C28.6421 32 32 28.6421 32 24.5V7.5C32 3.35786 28.6421 0 24.5 0Z"
        fill="#242938"
      />
      <path
        d="M11.6516 27.8675L6.25 6L27.9037 12.2343L11.6516 27.8675Z"
        stroke="white"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.0732 9.11768L19.772 20.0547L8.95117 16.9366L17.0732 9.11768Z"
        stroke="white"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.3879 18.4202L13.0474 12.987L18.4234 14.5303L14.3879 18.4202ZM11.7063 7.56055L13.0469 12.9937L7.6709 11.4504L11.7063 7.56055ZM22.4579 10.6577L23.7984 16.0908L18.4224 14.5475L22.4579 10.6577ZM14.3885 18.4239L15.729 23.857L10.353 22.3138L14.3885 18.4239Z"
        stroke="white"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_565_16178">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ThreejsIcon;
