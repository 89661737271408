import React from "react";

const HtmlIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.568 28.8011L2 0H30.2171L27.6457 28.7966L16.0914 32L4.568 28.8011Z"
      fill="#E44F26"
    />
    <path
      d="M16.1094 29.552L25.4465 26.9635L27.6431 2.35547H16.1094V29.552Z"
      fill="#F1662A"
    />
    <path
      d="M16.1091 13.0364H11.4348L11.1125 9.41929H16.1091V5.88672H7.25195L7.33652 6.83529L8.20395 16.569H16.1091V13.0364ZM16.1091 22.2101L16.0931 22.2147L12.1594 21.153L11.908 18.3359H8.36167L8.85652 23.881L16.0931 25.8901L16.1091 25.8856V22.2101Z"
      fill="#EBEBEB"
    />
    <path
      d="M16.0957 13.0364V16.569H20.4454L20.0363 21.1507L16.0957 22.2136V25.889L23.338 23.881L23.3906 23.2844L24.2203 13.985L24.3071 13.0364H16.0957ZM16.0957 5.88672V9.41929H24.6283L24.6991 8.62615L24.8603 6.83529L24.9448 5.88672H16.0957Z"
      fill="white"
    />
  </svg>
);

export default HtmlIcon;
