import React from "react";

const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill="currentColor"
    className="h-5 w-5"
    {...props}
  >
    <path d="M10.5 2.16895C6.084 2.16895 2.5 5.75295 2.5 10.1689C2.5 14.5849 6.084 18.1689 10.5 18.1689H14.5V16.5689H10.5C7.028 16.5689 4.1 13.6409 4.1 10.1689C4.1 6.69695 7.028 3.76895 10.5 3.76895C13.972 3.76895 16.9 6.69695 16.9 10.1689V11.3129C16.9 11.9449 16.332 12.5689 15.7 12.5689C15.068 12.5689 14.5 11.9449 14.5 11.3129V10.1689C14.5 7.96095 12.708 6.16895 10.5 6.16895C8.292 6.16895 6.5 7.96095 6.5 10.1689C6.5 12.3769 8.292 14.1689 10.5 14.1689C11.604 14.1689 12.612 13.7209 13.332 12.9929C13.852 13.7049 14.748 14.1689 15.7 14.1689C17.276 14.1689 18.5 12.8889 18.5 11.3129V10.1689C18.5 5.75295 14.916 2.16895 10.5 2.16895ZM10.5 12.5689C9.172 12.5689 8.1 11.4969 8.1 10.1689C8.1 8.84095 9.172 7.76895 10.5 7.76895C11.828 7.76895 12.9 8.84095 12.9 10.1689C12.9 11.4969 11.828 12.5689 10.5 12.5689Z" />
  </svg>
);

export default EmailIcon;
