import React from "react";

const ReactIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_565_14638)">
      <path
        d="M24.5 0H7.5C3.35786 0 0 3.35786 0 7.5V24.5C0 28.6421 3.35786 32 7.5 32H24.5C28.6421 32 32 28.6421 32 24.5V7.5C32 3.35786 28.6421 0 24.5 0Z"
        fill="#242938"
      />
      <path
        d="M16 18.3691C17.288 18.3691 18.332 17.325 18.332 16.0371C18.332 14.7492 17.288 13.7051 16 13.7051C14.7121 13.7051 13.668 14.7492 13.668 16.0371C13.668 17.325 14.7121 18.3691 16 18.3691Z"
        fill="#00D8FF"
      />
      <path
        d="M16.0002 11.2954C19.1312 11.2954 22.0399 11.7447 24.233 12.4998C26.8754 13.4094 28.5 14.7883 28.5 16.0369C28.5 17.338 26.7782 18.8029 23.9406 19.743C21.7954 20.4538 18.9724 20.8248 16.0002 20.8248C12.953 20.8248 10.0674 20.4765 7.89787 19.7352C5.15312 18.797 3.5 17.3133 3.5 16.0369C3.5 14.7984 5.05125 13.4302 7.6565 12.5219C9.85775 11.7544 12.8381 11.2954 15.9998 11.2954H16.0002Z"
        stroke="#00D8FF"
        strokeWidth="1.2"
      />
      <path
        d="M11.8724 13.6797C13.4366 10.9675 15.2788 8.67225 17.0285 7.14975C19.1365 5.31512 21.1428 4.59662 22.2243 5.22025C23.3514 5.87025 23.7603 8.09375 23.1571 11.0215C22.7015 13.2349 21.6123 15.8656 20.1274 18.4404C18.605 21.0801 16.8619 23.4059 15.136 24.9144C12.9521 26.8234 10.8408 27.5142 9.73513 26.8766C8.66225 26.2584 8.252 24.2307 8.76675 21.5201C9.20163 19.2297 10.293 16.4186 11.8719 13.6797H11.8724Z"
        stroke="#00D8FF"
        strokeWidth="1.2"
      />
      <path
        d="M11.8766 18.4473C10.308 15.7381 9.23996 12.9953 8.79421 10.7191C8.25759 7.97645 8.63696 5.87957 9.71759 5.25407C10.8437 4.60207 12.9741 5.35832 15.2093 7.34307C16.8993 8.84357 18.6347 11.1007 20.1238 13.6729C21.6506 16.3102 22.7948 18.9821 23.24 21.2306C23.8032 24.0761 23.3473 26.2502 22.2427 26.8896C21.171 27.5101 19.2096 26.8532 17.1182 25.0534C15.3512 23.5329 13.4607 21.1836 11.8766 18.4473Z"
        stroke="#00D8FF"
        strokeWidth="1.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_565_14638">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ReactIcon;
