import React from "react";

const JavascriptIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1007_3594)">
      <path
        d="M24.5 0H7.5C3.35786 0 0 3.35786 0 7.5V24.5C0 28.6421 3.35786 32 7.5 32H24.5C28.6421 32 32 28.6421 32 24.5V7.5C32 3.35786 28.6421 0 24.5 0Z"
        fill="#F0DB4F"
      />
      <path
        d="M8.41419 26.7414L10.8629 25.2594C11.3354 26.097 11.7652 26.8058 12.7961 26.8058C13.7842 26.8058 14.4072 26.4193 14.4072 24.9158V14.691H17.4144V24.9583C17.4144 28.0729 15.5887 29.4907 12.9249 29.4907C10.5193 29.4907 9.12294 28.2448 8.41406 26.7412M19.0478 26.4192L21.4963 25.0015C22.1409 26.0542 22.9787 26.8274 24.4607 26.8274C25.7068 26.8274 26.5013 26.2044 26.5013 25.3452C26.5013 24.3142 25.6851 23.9489 24.3103 23.3477L23.5587 23.0253C21.3891 22.1018 19.9498 20.9418 19.9498 18.493C19.9498 16.2375 21.6683 14.519 24.3534 14.519C26.2652 14.519 27.6399 15.185 28.6279 16.9249L26.2866 18.4285C25.7709 17.5049 25.2127 17.1398 24.3534 17.1398C23.4727 17.1398 22.9142 17.6983 22.9142 18.4285C22.9142 19.3307 23.4727 19.6959 24.7614 20.2545L25.5132 20.5767C28.0693 21.6723 29.5086 22.789 29.5086 25.3022C29.5086 28.0089 27.3821 29.4909 24.5252 29.4909C21.7328 29.4909 19.9284 28.1592 19.0478 26.4192Z"
        fill="#323330"
      />
    </g>
    <defs>
      <clipPath id="clip0_1007_3594">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default JavascriptIcon;
