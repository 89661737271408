import React from "react";

const LinkedInIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill="currentColor"
    className="h-5 w-5"
    {...props}
  >
    <path d="M18.5 11.9785V18.1689H15.0706V12.3929C15.0706 10.9416 14.5744 9.95166 13.3334 9.95166C12.3859 9.95166 11.8215 10.6194 11.5738 11.2646C11.4832 11.4953 11.46 11.8166 11.46 12.1396V18.1689H8.02893C8.02893 18.1689 8.07543 8.38707 8.02893 7.3728H11.46V8.90301L11.4375 8.93824H11.46V8.90301C11.9153 8.16895 12.729 7.11945 14.5511 7.11945C16.8077 7.11945 18.5 8.66307 18.5 11.9785ZM4.44078 2.16895C3.26797 2.16895 2.5 2.97431 2.5 4.03388C2.5 5.06995 3.24553 5.89965 4.39589 5.89965H4.41833C5.61519 5.89965 6.35831 5.06995 6.35831 4.03388C6.33747 2.97431 5.61519 2.16895 4.44078 2.16895ZM2.70362 18.1689H6.13305V7.3728H2.70362V18.1689Z" />
  </svg>
);

export default LinkedInIcon;
